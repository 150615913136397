import { computed, ComputedRef, isRef, MaybeRef, Ref } from 'vue';

export const useBooleanProp = (prop: MaybeRef<string | boolean | undefined | null>): ComputedRef<boolean> => {
  return computed((): boolean => {
    const value = isRef(prop) ? prop.value : prop;

    if (typeof value === 'undefined' || value === null) return false;
    if (typeof value === 'boolean') return value;

    return !['false', '0'].includes(value.toLowerCase().trim());
  })
}
