import { UIAlertModalPayload } from '@/ui/components/ui-alert-modal/types';
import useWebcomponent from '@/ui/composable/useWebcomponent';
import { UIKitComponent } from '@/ui/types/components';
import { UI_ZINDEX_MODAL } from '@/ui/utils/const';
import isCsr from '@/ui/utils/isCsr';
import uuid from '@/ui/utils/uuid';
import { getLoginFallbackByEventName } from './loginFallbackStrategy';

interface UseModal {
  toggleModal: (modalId: string, payload?: unknown, reload?: boolean) => void;
  toggleAsyncModal: (
    modalId: string,
    modalComponent: UIKitComponent,
    payload?: unknown,
    reload?: boolean,
  ) => Promise<void>;
  showAlert: (payload: UIAlertModalPayload) => Promise<void>;
  pushModal: (base: Node) => void;
  handleMountModal: () => Promise<void>;
}

export default function useModal (): UseModal {
  const toggleModal = (
    modalId: string,
    payload?: unknown,
    reload = false,
  ): void => {
    document.dispatchEvent(new CustomEvent('modal_toggle', {
      detail: {
        modalId,
        payload,
        reload,
      },
    }));
  }

  const toggleAsyncModal = async (
    modalId: string,
    modalComponent: UIKitComponent,
    payload?: unknown,
    reload = false,
  ): Promise<void> => {
    await useWebcomponent(modalComponent, true);
    document.dispatchEvent(new CustomEvent('modal_toggle', {
      detail: {
        modalId,
        payload,
        reload,
      },
    }));
  }

  const showAlert = async (
    payload: UIAlertModalPayload,
  ): Promise<void> => {
    await useWebcomponent('ui-alert-modal');
    document.dispatchEvent(new CustomEvent('modal_alert_show', {
      detail: payload,
    }));
  }

  const pushModal = (base: Node): void => {
    const baseHost = ((base?.parentNode as ShadowRoot)?.host as HTMLElement);
    baseHost?.style.setProperty('position', 'absolute');
    baseHost?.style.setProperty('z-index', `${UI_ZINDEX_MODAL + ++window.uikit.modalLastId}`);
  }

  const handleMountModal = async (): Promise<void> => {
    if (!isCsr) return;

    await getLoginFallbackByEventName()?.handleMount<{
      modalId: string;
      modalComponent: UIKitComponent;
      payload?: Record<string, unknown>;
    }>(async (fallbackEvent, payload): Promise<void> => {
      await toggleAsyncModal(payload.modalId, payload.modalComponent, {
        ...payload.payload,
        fallbackEvent,
      });
    });
  }

  return {
    toggleModal,
    toggleAsyncModal,
    showAlert,
    pushModal,
    handleMountModal,
  }
}
