import { Ref, ref, watch } from 'vue';
import isCsr from '../utils/isCsr';
import uuid from '../utils/uuid';

interface UseLockBody {
  pushLock: () => string;
  popLock: (id: string) => void;
  checkIsLocked: (id: string) => boolean;
}

export default function useLockBody (): UseLockBody {
  const handleLocked = (): void => {
    /**
     * Handling iOS 13+ by fixed body position
     */
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (window.uikit.bodyLockStack.length > 0) {
      if (isIOS) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.style.left = '0';
        document.body.style.right = '0';
      } else {
        document.body.style.width = `${document.body.getBoundingClientRect().width}px`;
        document.body.style.overflow = 'hidden';
      }
    } else {
      if (isIOS) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.left = '';
        document.body.style.right = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      } else {
        document.body.style.removeProperty('width');
        document.body.style.removeProperty('overflow');
      }
    }
  }

  const pushLock = (): string => {
    const id = uuid();

    window.uikit?.bodyLockStack.push(id);
    handleLocked();

    return id;
  }

  const popLock = (id: string): void => {
    const foundIndex = window.uikit.bodyLockStack.findIndex(
      (item) => id === item,
    );

    if (foundIndex >= 0) {
      window.uikit.bodyLockStack.splice(foundIndex, 1);
      handleLocked();
    }
  }

  const checkIsLocked = (id: string): boolean => {
    return window.uikit.bodyLockStack.some(
      (item) => id === item,
    );
  }

  isCsr && window.addEventListener('resize', () => {
    if (document.body.style.width !== '') {
      document.body.style.removeProperty('width');
      document.body.style.width = `${document.body.getBoundingClientRect().width}px`;
    }
  })

  return {
    popLock,
    pushLock,
    checkIsLocked,
  };
}
