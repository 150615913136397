
import {
  defineComponent,
  ref,
  onMounted,
  toRef,
  watch,
  onUnmounted,
  computed,
} from 'vue';
import useUIKit from '@/ui-kit/js/composable/useUIKit';
import usePlaceholder from '@/ui-kit/js/composable/usePlaceholder';
import { useBooleanProp } from '@/ui-kit/js/composable/useBooleanProp';
import { UITextfieldTypeEnum } from './types';
import { uiTextfieldProps } from './props';
import {UIKitButtonSizeEnum} from '@/ui/components/ui-button/types';
import {domains} from '@/js/const/mailSimilarityDomains';
import {findSimilarDomain} from '@/js/mailSimilarity/findSimilarMailDomain';

export default defineComponent({
  name: 'UITextfield',

  props: uiTextfieldProps,

  setup (props, { emit }) {
    const placeholder = usePlaceholder(props.isRendered);
    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement | null>();
    const input = ref<HTMLInputElement>();
    const value = toRef(props, 'value');
    const modelValue = ref(value.value);
    const focused = ref(false);
    const touched = ref(false);
    const inputType = ref(UITextfieldTypeEnum.Text);
    const hasError = useBooleanProp(toRef(props, 'error'));
    const hasHint = useBooleanProp(toRef(props, 'hint'));
    const isValid = ref(true);
    const dataList = ref<string[]>([]);
    const mailSuggestion = ref<{ value: string, message: string }>({value: '', message: ''});
    const inputComponent = computed(
      () => {
        switch (props.type) {
        case UITextfieldTypeEnum.Richtext:
          return 'textarea';
        default:
          return 'input';
        }
      },
    );
    const isMailType = computed(
      () => props.type === UITextfieldTypeEnum.Email,
    );


    const onFocus = (event: Event, propagation = true): void => {
      event.stopPropagation();
      input.value?.focus();
      focused.value = true;
      touched.value = true;

      if (propagation) {
        emit('focus');
        hostElement.value?.dispatchEvent(new CustomEvent('focus'));
      }
    }

    const onBlur = (event: FocusEvent | CustomEvent<void>): void => {
      event.stopPropagation();
      event.preventDefault();
      input.value?.blur();
      const relatedTarget = 'relatedTarget' in event ?
        event.relatedTarget as HTMLElement | null :
        null

      if (relatedTarget?.classList.contains('-clear')) return;

      focused.value = false;

      emit('blur');
      hostElement.value?.dispatchEvent(new CustomEvent('blur', { bubbles: false }));
    }

    const onKeyPress = (event: KeyboardEvent): void => {
      event.stopPropagation();
      modelValue.value = (event.target as HTMLInputElement).value;
      isValid.value = !!input.value?.validity.valid;

      hostElement.value?.dispatchEvent(new CustomEvent('valid', {
        detail: isValid.value,
      }));

      if (event.key === 'Enter') {
        hostElement.value?.dispatchEvent(new CustomEvent('enter'));
      }

      emit('keypress', {
        key: event.key ?? event.code,
        keyCode: event.keyCode ?? event.charCode ?? event.which,
        value: modelValue.value,
      });

      hostElement.value?.dispatchEvent(new CustomEvent('keypress', {
        detail: {
          key: event.key ?? event.code,
          keyCode: event.keyCode ?? event.charCode ?? event.which,
          value: modelValue.value,
        },
      }));
    }

    const handleUseSuggestion = (event: Event): void => {
      event.stopPropagation();
      modelValue.value = mailSuggestion.value.value;
      dataList.value = [];
      mailSuggestion.value.value = '';
      mailSuggestion.value.message = '';

      emit('input', modelValue.value);
      hostElement.value?.dispatchEvent(new CustomEvent('input', {
        detail: modelValue.value,
      }));
    }

    const findMailSuggestions = (): void => {
      const atIndex = modelValue.value.indexOf('@');
      if (atIndex !== -1) {
        const typedDomain = modelValue.value.slice(atIndex + 1);
        const matchingDomains = domains.filter((domain) => domain.startsWith(typedDomain));
        dataList.value = [];
        matchingDomains.forEach((domain) => dataList.value.push(modelValue.value.slice(0, atIndex + 1) + domain));

        const similarDomain = findSimilarDomain(typedDomain);

        if (similarDomain && similarDomain !== typedDomain) {
          mailSuggestion.value.value = modelValue.value.slice(0, atIndex + 1) + similarDomain;
          mailSuggestion.value.message = `Czy chodziło Ci o
          ${modelValue.value.slice(0, atIndex + 1)}${similarDomain}?`;
        } else {
          mailSuggestion.value.value = '';
          mailSuggestion.value.message = '';
        }
      } else {
        dataList.value = [];
        mailSuggestion.value.value = '';
        mailSuggestion.value.message = '';
      }
    }

    const onInput = (event: Event): void => {
      event.stopPropagation();

      modelValue.value = (event.target as HTMLInputElement).value;
      isValid.value = !!input.value?.validity.valid;

      hostElement.value?.dispatchEvent(new CustomEvent('valid', {
        detail: isValid.value,
      }));


      emit('input', modelValue.value);
      hostElement.value?.dispatchEvent(new CustomEvent('input', {
        detail: modelValue.value,
      }));

      if (isMailType.value) {
        findMailSuggestions();
      }
    }

    const onClearValue = (event: Event): void => {
      event.stopPropagation();
      event.preventDefault();
      modelValue.value = '';
      input.value?.focus();
      focused.value = true;

      emit('input', modelValue.value);
      hostElement.value?.dispatchEvent(new CustomEvent('input', {
        detail: modelValue.value,
      }));
    }

    const togglePasswordVisibility = (event: Event): void => {
      event.stopPropagation();

      if (props.type !== UITextfieldTypeEnum.Password) return;

      inputType.value = inputType.value === UITextfieldTypeEnum.Text ?
        UITextfieldTypeEnum.Password :
        UITextfieldTypeEnum.Text;
    }

    onMounted(async () => {
      const { host } = await useUIKit(component, 'ui-textfield');
      placeholder.init(component, () => {
        host?.classList.add('--done');
      });

      hostElement.value = host;

      hostElement.value?.dispatchEvent(new CustomEvent('mounted'));

      props.imask && props.mask && input.value && props.imask(input.value, {
        mask: props.mask,
      });

      inputType.value = props.type;
      hostElement.value?.addEventListener('focus', (event: Event) => onFocus(event, false));
      hostElement.value?.addEventListener('textfield_force_blur', onBlur);
      hostElement.value?.addEventListener('textfield_force_focus', onFocus);
    });

    onUnmounted(() => {
      hostElement.value?.removeEventListener('focus', onFocus);
      hostElement.value?.removeEventListener('textfield_force_blur', onBlur);
      hostElement.value?.removeEventListener('textfield_force_focus', onFocus);
    });

    watch(
      () => props.value,
      () => modelValue.value = props.value,
    );

    return {
      UITextfieldTypeEnum,
      UIKitButtonSizeEnum,
      placeholder,
      component,
      input,
      modelValue,
      focused,
      touched,
      inputType,
      hasError,
      hasHint,
      isValid,
      inputComponent,
      dataList,
      mailSuggestion,
      handleUseSuggestion,
      onFocus,
      onBlur,
      onKeyPress,
      onInput,
      onClearValue,
      togglePasswordVisibility,
    }
  },
});
