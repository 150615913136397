export default async function copyToClipboard (
  text: string,
  copyCallback: CallableFunction,
  clearCallback: CallableFunction,
): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    copyCallback();
    setTimeout(() => clearCallback(), 1500);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}
