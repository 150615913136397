import useMeta from './useMeta';

interface UseEnsureGtmCallback {
  ensureGtmCallback: (
    callback: () => void,
    id?: string,
  ) => void,
}

export default function useEnsureGtmCallback (): UseEnsureGtmCallback {
  const {
    gtmId,
  } = useMeta();

  let ensureEventCallbackTimeout: ReturnType<typeof setTimeout>;

  const ensureGtmCallback = (
    callback: () => void,
    id?: string,
  ): void => {
    clearTimeout(ensureEventCallbackTimeout);
    if (id === gtmId.value) {
      callback();
    } else {
      ensureEventCallbackTimeout = setTimeout(
        () => {
          callback();
        },
        3000,
      );
    }
  };

  return {
    ensureGtmCallback,
  }
}
