import useModal from '@/ui/composable/useModal';
import { StrategyNotExistsException } from './exceptions';
import uuid from '@/ui/utils/uuid';
import { UIKitComponent } from '@/ui/types/components';

namespace LoginFallbackStrategy {
  export abstract class LoginFallbackEvent {
    protected fallbackEvent: string;
    static payloadField: string;

    constructor (protected url: URL) {
      this.url = url;
      this.fallbackEvent = `fallback_${uuid()}`;
    }
    abstract handleMount<HandleMountPayloadType = unknown> (
      callback?: (
        fallbackEvent: string,
        payload: HandleMountPayloadType,
      ) => Promise<void>,
    ): unknown;

    abstract enrichUrl (url: URL, payload: unknown): void;
  }

  export class OpenModal extends LoginFallbackEvent {
    static payloadField = 'modalId';

    async handleMount<HandleMountPayloadType> (
      callback?: (
        fallbackEvent: string,
        payload: HandleMountPayloadType,
      ) => Promise<void>,
    ): Promise<void> {
      const openModal = this.url.searchParams.get('openModal') as string;
      const payload = JSON.parse(decodeURIComponent(openModal)) as HandleMountPayloadType;

      document.addEventListener(this.fallbackEvent, () => {
        this.url.searchParams.delete('openModal');
        window.history.pushState({}, document.title, this.url.toString());
      }, {
        once: true,
      });

      callback && await callback(this.fallbackEvent, payload);
    }

    enrichUrl (url: URL, payload: unknown): void {
      url.searchParams.set('openModal', encodeURIComponent(
        JSON.stringify(payload),
      ));
    }
  }

  /**
   * Strategy handler will fired when basket desynchronize event has been received.
   */
  export class EmitEvent extends LoginFallbackEvent {
    static payloadField = 'event';

    handleMount (): void {
      const emitEvent = this.url.searchParams.get('emitEvent') as string;
      const payload = JSON.parse(decodeURIComponent(emitEvent)) as {
        event: string;
        payload?: unknown;
      };

      document.addEventListener(this.fallbackEvent, () => {
        this.url.searchParams.delete('emitEvent');
        window.history.pushState({}, document.title, this.url.toString());
      }, {
        once: true,
      });

      document.dispatchEvent(new CustomEvent(payload.event, {
        detail: {
          productId: payload.payload,
          fallbackEvent: this.fallbackEvent,
        },
      }));
    }

    enrichUrl (url: URL, payload: unknown): void {
      url.searchParams.set('emitEvent', encodeURIComponent(
        JSON.stringify(payload),
      ));
    }
  }
}

export function getLoginFallbackByPayloadParam (
  payload: Record<string, unknown>,
  url?: URL,
): LoginFallbackStrategy.LoginFallbackEvent | null {
  url = url || new URL(window.location.href);

  for (const className of Object.keys(LoginFallbackStrategy)) {
    if (LoginFallbackStrategy[
      className as keyof typeof LoginFallbackStrategy
    ].payloadField in payload) {
      return loginFallbackStrategy(url, className);
    }
  }

  return null;
}

export function getLoginFallbackByEventName (
  url?: URL | string,
): LoginFallbackStrategy.LoginFallbackEvent | null {
  url = (url instanceof URL ? url : new URL(url || window.location.href));

  for (const value of url.searchParams.keys()) {
    const tmpClass = value.charAt(0).toUpperCase() + value.slice(1);
    if (tmpClass in LoginFallbackStrategy) {
      return loginFallbackStrategy(url, tmpClass);
    }
  }

  return null;
}

export function loginFallbackStrategy (
  url: URL,
  className: string,
): LoginFallbackStrategy.LoginFallbackEvent | null {
  if (null === className) {
    return null;
  }

  let strategyObject: LoginFallbackStrategy.LoginFallbackEvent;

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    strategyObject = new LoginFallbackStrategy[className](url);
  } catch (e) {
    console.error(e)
    throw new StrategyNotExistsException(className);
  }

  if (!(strategyObject instanceof LoginFallbackStrategy.LoginFallbackEvent)) {
    throw new StrategyNotExistsException(className);
  }

  return strategyObject;
}
