
import { PropType, defineComponent, onMounted, ref, watch } from 'vue';
import useUIKit from '@/ui-kit/js/composable/useUIKit';
import { ColorPalette } from '@/ui/types/colors';
import { UiColorDensityEnum } from '@/js/api/generated';
import { UIKitButtonSizeEnum, UIKitButtonTargetEnum } from './types';
import createRipple from '@/ui/utils/ripple';

export default defineComponent({
  name: 'UIButton',
  props: {
    id: {
      type: String,
      default: undefined,
    },
    color: {
      type: String as PropType<ColorPalette>,
      default: ColorPalette.Secondary,
    },
    density: {
      type: Number as PropType<UiColorDensityEnum>,
      default: UiColorDensityEnum._500,
    },
    borderColor: {
      type: String as PropType<keyof ColorPalette | undefined>,
      default: undefined,
    },
    borderDensity: {
      type: Number as PropType<UiColorDensityEnum>,
      default: UiColorDensityEnum._500,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    gutter: {
      type: String as PropType<'xxs' | 'xs' | 'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    href: {
      type: String || undefined,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    glueLeft: {
      type: Boolean,
      default: false,
    },

    glueRight: {
      type: Boolean,
      default: false,
    },
    nogutter: {
      type: Boolean,
      default: false,
    },
    noradius: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<UIKitButtonSizeEnum>,
      default: UIKitButtonSizeEnum.Medium,
    },
    target: {
      type: String as PropType<UIKitButtonTargetEnum>,
      default: UIKitButtonTargetEnum.Self,
    },
    strong: {
      type: Boolean,
      default: false,
    },
    tight: {
      type: Boolean,
      default: false,
    },
    textOverflow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    usedExternal: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String as PropType<'nofollow' | 'noopener' | 'noreferrer'>,
      default: undefined,
    },
  },

  setup (props) {
    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement>();

    const handleClick = (event: MouseEvent): void => {
      component.value && createRipple(event, component.value);
    }

    onMounted(async () => {
      const { host } = await useUIKit(component, 'ui-button', {
        usedExternal: props.usedExternal,
      });
      hostElement.value = host as HTMLElement;

      hostElement.value?.addEventListener('focus', () => component.value?.focus());
    })

    return {
      component,
      handleClick,
    }
  },
});
