import { createSSRApp } from 'vue';
import UIPageFooter from './UIPageFooter.vue';
const app = createSSRApp(UIPageFooter, {
  payload: window.uiPageFooterProps,
})

// mounting an SSR app on the client assumes
// the HTML was pre-rendered and will perform
// hydration instead of mounting new DOM nodes.
app.mount('.ui-page-footer-container')
