import { createSSRApp } from 'vue';
import UIPageHeader from './UIPageHeader.vue';
const app = createSSRApp(UIPageHeader, {
  menu: window.uiMenu,
  isExpandable: window.uiMenuExpandable,
})

// mounting an SSR app on the client assumes
// the HTML was pre-rendered and will perform
// hydration instead of mounting new DOM nodes.
app.mount('.ui-page-header-container')
