import { PropType } from 'vue';

export enum UITextfieldTypeEnum {
  Text = 'text',
  Email = 'email',
  Richtext = 'richtext',
  Password = 'password',
}

export enum UITextfieldSizeEnum {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export interface UITextfieldEventMapInterface {
  'textfield_force_blur': CustomEvent<void>;
}
