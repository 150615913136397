import useModal from './useModal';
import {UIAlertModalTypeEnum} from '@/ui/components/ui-alert-modal/types';
import Route from '@/js/utils/route';
import useLoader from './useLoader';
import {frontApi} from '@/api/useFrontendApi';
import {ApiError} from '@/api/generated';

interface UseUserNotification {
  handle: () => void;
}

export default function useUserNotification (): UseUserNotification {
  const { showAlert } = useModal();
  const loaded = useLoader();

  const handleAddUserNotification = async (
    event: Event,
  ): Promise<void> => {
    const { toggleAsyncModal } = useModal();

    if (!window.auth) {
      await toggleAsyncModal('ui-login-modal', 'ui-login-modal');

      return;
    }

    const payload = (event as CustomEvent<{
      productId: number,
      fallbackEvent?: string,
    }>).detail;

    try {
      loaded.show();
      const response = await frontApi.notification.addProductNotification({
        requestBody: {
          productId: payload.productId,
        },
      });
      await showAlert({
        type: UIAlertModalTypeEnum.Success,
        heading: 'Utworzono powiadomienie',
        content: response.message ?? '',
        button: {
          label: 'Lista Powiadomień',
          callback: (): void => {
            window.location.href = Route.get('user-product-notifications-index');
          },
        },
      });
    } catch (e) {
      let message = 'Problem z dodaniem powiadomienia.';
      if (e instanceof ApiError && 'message' in e.body) {
        message = e.body.message;
      }
      await showAlert({
        type: e instanceof ApiError && e.status === 400 ? UIAlertModalTypeEnum.Info : UIAlertModalTypeEnum.Error,
        heading: e instanceof ApiError && e.status === 400 ? 'Informacja' : 'Wystąpił błąd',
        content: message,
        button: {
          label: 'Lista Powiadomień',
          callback: (): void => {
            window.location.href = Route.get('user-product-notifications-index');
          },
        },
      });
    } finally {
      payload.fallbackEvent && document.dispatchEvent(
        new CustomEvent(payload.fallbackEvent),
      );
      loaded.hide();
    }
  }

  const handle = (): void => {
    document.addEventListener('product_notify_when_available', handleAddUserNotification);
  };

  return {
    handle,
  }
}
