import { ComputedRef, Ref, computed, ref, watch } from 'vue';

interface UsePrice {
  currency: Ref<string>;
  mappedCurrencySymbol: ComputedRef<UIUsePriceSymbolMapEnum>;
  format: (price: number, withSymbol?: boolean) => string;
};

enum UIUsePriceSymbolMapEnum {
  PLN = 'zł',
}

export default function usePrice (): UsePrice {
  const currency = ref<keyof typeof UIUsePriceSymbolMapEnum>('PLN');
  const locale = ref('pl-PL');

  const formatter = ref(
    new Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency: currency.value,
      currencyDisplay: 'code',
    }),
  );

  const mappedCurrencySymbol = computed(
    () => UIUsePriceSymbolMapEnum[currency.value],
  );

  const format = (price: number, withSymbol = true): string => {
    const text = formatter.value.format(price);

    if (!withSymbol) {
      return text.replace(`${currency.value}`, '').trim();
    }

    return text;
  }

  watch(
    () => [currency.value, locale.value],
    () => {
      formatter.value = new Intl.NumberFormat(locale.value, {
        style: 'currency',
        currency: currency.value,
        currencyDisplay: 'code',
      });
    },
  );

  return {
    currency,
    mappedCurrencySymbol,
    format,
  };
}
