import { EventSourcePolyfill } from 'event-source-polyfill';

export interface UIMercureInterface {
  uiMercureHost: string;
  uiMercureSources: Record<string, EventSourcePolyfill>;
  uiMercureJwt: string;
  uiMercureInpostTopics: {
    Basket: string;
    Binding: string;
  };
}

export enum UIMercureExceptionCodeEnum {
  GeneralError = 1,
  NonExistentEventSourceError = 2,
  EmptyTokenError = 3,
  CreateEventSourceError = 4,
}

export type UIMercureEvent<Data> = {
  data: Data;
  event: string;
}

export type UIMercureSubscribePayload<Data> = {
  topic: string,
  callback: (data: UIMercureEvent<Data>) => void,
  closeImmediately?: boolean,
}

export class UIMercureExcpetion extends Error {
  public mercureCode: UIMercureExceptionCodeEnum;

  constructor (payload: { code: UIMercureExceptionCodeEnum, message: string }) {
    super(payload.message);

    this.mercureCode = payload.code;
  }
}
