import { Product } from '@/js/api/generated';

/**
 * TODO: Maybe all those types and enums should be generated by openapis?
 */
export enum IziBindingPlaceEnum {
  ProductCard = 'PRODUCT_CARD',
  BasketSummary = 'BASKET_SUMMARY',
  OrderCreate = 'ORDER_CREATE',
  BasketPopup = 'BASKET_POPUP',
}

export type IziGetPayDataResult = {
  qr_code: string,
  deep_link: string,
  deep_link_hms: string,
} | [];

export type IziGetBrowserDataResult = {
  user_agent: string;
  description: string;
  platform: string;
  architecture: string;
}

export type IziGetIsBoundResult = {
  phone_number: {
    country_prefix: string;
    phone: string;
  },
  browser: {
    browser_trusted: boolean;
    browser_id: string;
  },
  name: string;
  surname: string;
  masked_phone_number: string;
}

export type IziGetOrderCompleteResult = {
  action: string;
  redirect: string;
}

export type IziMobileLinkResult = {
  link: string;
}

export type IziGetBindingDataResult = {
  basket_linked: boolean;
  browser_trusted: boolean;
}

export interface InPostPayInterface {
  uiInpostPayEnabled?: boolean;
  uiInpostPayEnv: string;
  uiWarehouseId?: string;
  iziGetPayData: (
    prefix: string,
    phoneNumber: string,
    bindingPlace: IziBindingPlaceEnum,
  ) => Promise<IziGetPayDataResult>;
  iziGetBrowserData: (base64: boolean) => IziGetBrowserDataResult | string;
  iziGetIsBound: () => Promise<IziGetIsBoundResult>;
  iziGetOrderComplete: () => Promise<IziGetOrderCompleteResult>;
  iziBindingDelete: () => Promise<void>;
  iziCanBeBound: (productId: Product['productId']) => Promise<boolean>;
  iziAddToCart: (productId: Product['productId']) => void;
  iziMobileLink: () => Promise<IziMobileLinkResult>;
  iziGetBindingData: (
    id: number,
    prefix: string,
    phoneNumber: string,
  ) => Promise<IziGetBindingDataResult>;
  handleInpostIziButtons: () => void;
}
