import UIBreadcrumb from './UIBreadcrumb.vue';

import {createSSRApp} from 'vue';
const app = createSSRApp(UIBreadcrumb, {
  items: window.uiBreadcrumbsItems,
})

// mounting an SSR app on the client assumes
// the HTML was pre-rendered and will perform
// hydration instead of mounting new DOM nodes.
if (document.getElementsByClassName('ui-breadcrumbs-container').length > 0) {
  app.mount('.ui-breadcrumbs-container')
}
