import Route from '@/js/utils/route';
import UserHistoryRequest from '../interface/UserHistoryRequest';

export default async function createProductUserHistory (
  userHistoryRequest: UserHistoryRequest,
  eventType: string,
): Promise<Response|void> {
  function requestHistoryApi () {
    let apiRoute = Route.get('api.product.history.visit');
    if (eventType === 'addToBasket') {
      apiRoute = Route.get('api.product.history.add-basket');
    } else if (eventType === 'orderPlaced') {
      apiRoute = Route.get('api.product.history.buy-product');
    }

    return fetch(apiRoute, {
      method: 'POST',
      body: JSON.stringify(userHistoryRequest),
    });
  }

  if (window.cookieStrategy !== 'cookiebot_based') {
    return requestHistoryApi();
  }

  window.addEventListener('CookiebotOnConsentReady', function () {
    if (!window.Cookiebot.consent.statistics) {
      return Promise.resolve();
    }
    return requestHistoryApi();
  })
}
