import { Ref, nextTick, ref } from 'vue';
import isCsr from '../utils/isCsr';
import * as Cookies from 'es-cookie';
interface UseMeta {
  token: Ref<string>;
  gtmId: Ref<string | undefined>;
}

export default function useMeta (): UseMeta {
  const token = ref('');
  const gtmId = ref();

  nextTick().then(() => {
    if (!isCsr) return;

    token.value = Cookies.get('XSRF_TOKEN') ?? ''

    gtmId.value = document.body.dataset.gtmId;
  }).catch(() => {});

  return {
    token,
    gtmId,
  }
}
