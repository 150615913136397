export enum UIKitButtonSizeEnum {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum UIKitButtonTargetEnum {
  Self = '_self',
  Blank = '_blank',
}
