export const domains = [
  'gmail.com',
  'onet.pl',
  'o2.pl',
  'tlen.pl',
  'interia.pl',
  'wp.pl',
  'icloud.com',
];
export const domainsForSimilar = [
  'gmail.com',
  'op.pl',
  'poczta.onet.pl',
  'onet.eu',
  'vp.pl',
  'buziaczek.pl',
  'poczta.onet.eu',
  'amorki.pl',
  'autograf.pl',
  'o2.pl',
  'go2.pl',
  'tlen.pl',
  'prokonto.pl',
  'interia.pl',
  'wp.pl',
  'gazeta.pl',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'poczta.fm',
  'autograf.pl',
  'spoko.pl',
  'outlook.com',
  'live.com',
]
