
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  PropType,
} from 'vue';
import useUIKit from '@/ui/composable/useUIKit';
import usePlaceholder from '@/ui/composable/usePlaceholder';
import { UIBreadcrumbItem } from './types';

export default defineComponent({
  name: 'UIBreadcrumb',
  props: {
    items: {
      type: String || Array as PropType<Array<UIBreadcrumbItem>>,
      default: null,
    },
  },

  setup (props) {
    const placeholder = usePlaceholder(true);
    const component = ref<HTMLElement>();

    const itemsPayload = computed<Array<UIBreadcrumbItem>>(
      () => typeof props.items === 'string' ?
        JSON.parse(props.items) as UIBreadcrumbItem[] :
        props.items,
    );

    onMounted(async () => {
      await useUIKit(component, 'ui-breadcrumb');
      if (placeholder) {
        placeholder.init(component);
      }
    });

    return {
      placeholder,
      component,
      itemsPayload,
    }
  },
});
