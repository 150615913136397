
export enum ColorPalette {
  Primary = 'primary',
  Secondary = 'secondary',
  Gray = 'gray',
  GrayBlue = 'gray-blue',
  Red = 'red',
  Green = 'green',
  Yellow = 'yellow',
  Purple = 'purple',
  Pink = 'pink',
  White = 'white',
}

export type ColorDensity =
  | '25'
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
