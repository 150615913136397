import { UICartUpdateEvent, UICartUpdateEventSource } from '@/ui/composable/useCart';
import { UIAlertModalTypeEnum } from '@/ui/components/ui-alert-modal/types';
import useModal from '@/ui/composable/useModal';
import { StrategyNotExistsException } from './exceptions';
import {
  BasketConfirmedEventPayload,
  BasketEventStrategyOptions,
  BasketUpdatedEventPayload,
  BasketOrderEventResult,
} from './types';

namespace InpostPayBasketStrategy {
  export abstract class BasketEvent {
    abstract handle ({ payload, resolve, options }: {
      payload?: unknown,
      resolve?: CallableFunction,
      options?: BasketEventStrategyOptions,
    }): unknown;
  }

  /**
   * Strategy handler fired when any update basket event has receinved and
   * dispatching global cart_update event for refresh webcomponent states.
   */
  export class BasketUpdatedEvent extends BasketEvent {
    async handle ({ payload, options }: {
      payload: BasketUpdatedEventPayload,
      options: BasketEventStrategyOptions,
    }): Promise<void> {
      const { showAlert } = useModal();

      document.dispatchEvent(new CustomEvent<UICartUpdateEvent>('cart_update', {
        detail: {
          cartCount: payload.data.itemsCount,
          cartTotal: payload.data.basketTotal,
          source: UICartUpdateEventSource.InpostPay,
        },
      }));

      options.basketMode && await showAlert({
        content: 'Zaktualizowano koszyk w aplikacji Inpost Pay. Odśwież stronę.',
        type: UIAlertModalTypeEnum.Info,
        heading: 'Aktualizacja koszyka',
        button: {
          label: 'Odśwież',
          callback: (): void => location.reload(),
        },
      })
    }
  }

  /**
   * Strategy handler will fired when basket desynchronize event has been received.
   */
  export class BasketDesynchronizedEvent extends BasketEvent {
    handle ({ resolve }: {
      resolve: CallableFunction;
    }): void {
      resolve({
        action: 'refresh',
      })
    }
  }

  export class BasketConfirmedEvent extends BasketEvent {
    handle ({ payload, resolve }: {
      payload: BasketConfirmedEventPayload;
      resolve: CallableFunction;
    }): void {
      resolve({
        phone_number: {
          country_prefix: payload.country_prefix,
          phone: payload.phone,
        },
        browser: {
          browser_id: payload.browser.id,
          browser_trusted: payload.browser.trusted,
        },
        name: payload.name,
        surname: payload.surname,
        masked_phone_number: payload.phone,
      })
    }
  }

  export class BasketOrderEvent extends BasketEvent {
    handle ({ payload, resolve }: {
      payload: BasketOrderEventResult,
      resolve: CallableFunction,
    }): void {
      resolve(payload.data)
    }
  }
}

export default function mercureEventHandlerStrategy ({
  event, payload, resolve, options,
}: {
  event: string,
  payload: unknown,
  resolve: CallableFunction,
  options: BasketEventStrategyOptions,
}): void {
  const className = `${event.split('\\').at(-1)}` as Exclude<
    keyof typeof InpostPayBasketStrategy, 'BasketEvent'
  >;
  let strategyObject: InpostPayBasketStrategy.BasketEvent;

  try {
    console.debug('[EventSource]', className);
    strategyObject = new InpostPayBasketStrategy[className]();
  } catch (e) {
    console.error(e)
    throw new StrategyNotExistsException(className);
  }

  if (!(strategyObject instanceof InpostPayBasketStrategy.BasketEvent)) {
    throw new StrategyNotExistsException(className);
  }

  strategyObject.handle({ payload, resolve, options });
}
