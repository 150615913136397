import UIKitElement from '@/ui-kit/js/UIKitElement';
import UIBadgeHTML from '@/ui-kit/js/components/ui-badge/UIBadge.html';

import './UIBadge.scss';

class UIBadge extends UIKitElement {
  private _template: Document;

  constructor () {
    super('ui-badge', {
      tag: 'span',
      classes: ['ui-badge'],
    });

    this._template = (new DOMParser()).parseFromString(UIBadgeHTML, 'text/html');
  }

  connectedCallback (): void {
    this.render();
  }

  private render (): void {
    const size = this.getAttribute('size') ?? '1';
    const text = this.getAttribute('text');

    const backgroundColor = this.getAttribute('background-color') ?? 'gray-blue-100';
    const textColor = this.getAttribute('text-color') ?? 'gray-blue-700';

    const icon = this.getAttribute('icon');
    const iconSize = +size * 10;
    const iconColorParts = textColor.split('-');
    const iconColorDensity = iconColorParts.pop();
    const iconColor = iconColorParts.join('-');

    const variant = icon ? 'with-icon' : 'with-text' as const;

    this.container.classList.add(`${this.component}--${variant}`);
    this.container.style.setProperty(`--${this.component}-size-modifier`, size);
    this.container.style.setProperty(`--${this.component}-background-color`, `var(--ui-colors-${backgroundColor})`);
    this.container.style.setProperty(`--${this.component}-text-color`, `var(--ui-colors-${textColor})`);

    const template = this._template.querySelector(`template#${variant}`)!;

    this.container.innerHTML = this.interpolate(template.innerHTML, {
      component: this.component,
      text,
      icon,
      iconSize,
      iconColor,
      iconColorDensity,
    });
  }
}

export default UIBadge;
