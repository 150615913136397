import URI from 'urijs';
import routesJson from '@/js/routes.json';

interface RoutesData {
  [index: string]: string;
}

const routes = routesJson as RoutesData;

export default class Route {
  public static get (routeName: string, replacements: RoutesData = {}): string {
    let uri = routes[routeName];

    if (uri === undefined) {
      console.error('Cannot find route:', routeName);
      return '';
    }

    Object.keys(replacements).forEach(
      (key) =>
        (uri = uri.replace(new RegExp('{' + key + '\\??}'), replacements[key])),
    );

    // finally, remove any leftover optional parameters (inc leading slash)
    return uri.replace(/\/{[a-zA-Z]+\?}/, '');
  }

  public static isCurrentRoute (routeName: string): boolean {
    const url = this.get(routeName, {});
    const currentUri = new URI(location.href);

    return currentUri.path() === url;
  }
}
