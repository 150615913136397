import { Ref, ref } from 'vue';
import isCsr from '../utils/isCsr';

enum BreakpointEnum {
  Phone = 0,
  Tablet = 768,
  Desktop = 1280,
  Fullhd = 1920,
}

export interface UseBreakpoint {
  isPhone: Ref<boolean>;
  isMobile: Ref<boolean>;
}

const isPhone = ref(isCsr ?
  window.innerWidth < Number(BreakpointEnum.Tablet) :
  false,
);

const isMobile = ref(isCsr ?
  window.innerWidth < Number(BreakpointEnum.Desktop) :
  false,
);

export default function useBreakpoint (initBreakpoint?: {
  isPhone?: boolean;
  isMobile?: boolean;
}): UseBreakpoint {
  const calcBreakpoint = (): void => {
    isPhone.value = window.innerWidth < Number(BreakpointEnum.Tablet);
    isMobile.value = window.innerWidth < Number(BreakpointEnum.Desktop);
  };

  isCsr && window.addEventListener('resize', () => {
    calcBreakpoint();
  });

  if (initBreakpoint?.isPhone !== undefined) {
    isPhone.value = initBreakpoint.isPhone;
  }

  if (initBreakpoint?.isMobile !== undefined) {
    isMobile.value = initBreakpoint.isMobile;
  }

  return {
    isPhone,
    isMobile,
  }
}
