import { defineCustomElement } from 'vue';

import '@/ui/components/ui-breadcrumb/index';
import '@/ui/components/ui-page-header/index';
import '@/ui/components/ui-page-footer/index';
import UIButton from '@/ui/components/ui-button/UIButton.vue';
import UITextfield from '@/ui/components/ui-textfield/UITextfield.vue';
import UIBadge from '@/ui/components/ui-badge/UIBadge';

if (!customElements.get('ui-button')) {
	customElements.define('ui-button', defineCustomElement(UIButton));
}
if (!customElements.get('ui-textfield')) {
	customElements.define('ui-textfield', defineCustomElement(UITextfield));
}
if (!customElements.get('ui-badge')) {
	customElements.define('ui-badge', UIBadge);
}
