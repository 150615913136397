import { PropType } from 'vue';
import { UITextfieldSizeEnum, UITextfieldTypeEnum } from './types';

export const uiTextfieldProps = {
  name: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: null,
  },
  list: {
    type: String,
    default: null,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  glueLeft: {
    type: Boolean,
    default: false,
  },
  glueRight: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  maxLength: {
    type: Number,
    default: undefined,
  },
  minLength: {
    type: Number,
    default: undefined,
  },
  placeholderText: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    default: '',
  },
  focusRingColor: {
    type: String,
    default: null,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<UITextfieldTypeEnum>,
    default: UITextfieldTypeEnum.Text,
  },
  rows: {
    type: Number,
    default: 3,
  },
  counter: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<UITextfieldSizeEnum>,
    default: UITextfieldSizeEnum.Medium,
  },
  isRendered: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: String,
    default: null,
  },
  imask: {
    type: Function,
    default: null,
  },
}
