
import {
  PropType,
  defineComponent,
  ref,
  watch,
} from 'vue';
import {
  UIPageHeaderMegaMenuState,
  UIPageHeaderSection,
  UIPageHeaderAsyncMenuItem,
  UIPageHeaderMenuItem,
} from './types';
import uuid from '@/ui/utils/uuid';
import useBreakpoint from '@/ui/composable/useBreakpoint';
import { DeviceVisibilityEnum, EntityType, Product } from '@/js/api/generated';
import useLoader from '@/ui/composable/useLoader';
import { frontApi } from '@/js/api/useFrontendApi';

type LegacyFetchCategory = {
  children: Array<{
    id: number;
    name: string;
    slug: string;
    suggestedName: string | null;
    parentCategories: Array<unknown>;
  }>
}

export default defineComponent({
  props: {
    megaMenuState: {
      type: Object as PropType<UIPageHeaderMegaMenuState>,
      required: true,
    },
    showMoreLink: {
      type: String,
      default: null,
    },
    menuItem: {
      type: Object as PropType<UIPageHeaderMenuItem>,
      default: null,
    },
    level: {
      type: Number,
      default: 1,
    },
    productCache: {
      type: Object as PropType<Record<string, Product>>,
      default: undefined,
    },
  },

  emits: ['pushMenu', 'popMenu', 'toggleMegaMenu', 'updateProductCache'],

  setup (props, { emit }) {
    const {
      isMobile,
    } = useBreakpoint();

    const loader = useLoader();

    const sectionDropdowns = ref<HTMLAnchorElement[]>();
    const toggleState = ref<Record<string, boolean>>({});

    const hasChildren = (
      item: UIPageHeaderSection,
    ): boolean => {
      return 'name' in item && item.childrenCategories.length > 0;
    }

    const handleToggleDropdown = (
      event: MouseEvent,
      section: UIPageHeaderSection,
    ): void => {
      if (!hasChildren(section)) return;

      if (!isMobile.value) return;

      event.preventDefault();
      const dropdown = (
        event.target as HTMLElement
      ).closest(
        '.-nav-mega__section',
      )?.querySelector(
        '.-nav-mega__dropdown',
      ) as HTMLElement | undefined;

      const dropdownList = dropdown?.querySelector(
        '.-nav-mega__dropdown-list',
      ) as HTMLElement | undefined;

      if (!dropdown || !dropdownList) {
        return;
      }

      toggleState.value[section.id] = dropdown.getBoundingClientRect().height > 0;
    }

    const loadMenu = async (
      event: MouseEvent,
      item: UIPageHeaderAsyncMenuItem,
    ): Promise<void> => {
      if (!isMobile.value) return;
      event.preventDefault();

      if (!item.entityId) {
        return;
      }

      if (item.slug && !item.hasChildren) {
        loader.show();
        window.location.pathname = item.slug;
        return;
      }

      try {
        loader.show();
        // TODO: should be fetched from tantis service, but its not ready yet
        const res = await fetch(`/clientApi/category-get-children/${item.entityId}`, { headers: {
          'Accept': 'application/json',
        }});
        if (res.status !== 200 && item.slug) {
          window.location.pathname = item.slug;
          return;
        }
        const data = await res.json() as LegacyFetchCategory;
        const menuItem: UIPageHeaderMenuItem = {
          id: uuid(),
          entityId: item.entityId,
          name: item.name,
          toggleMegaMenu: false,
          slug: item.slug,
          visibility: DeviceVisibilityEnum.MOBILE,
          columns: [
            [
              ...data.children.map((section) => ({
                id: uuid(),
                name: section.name,
                slug: section.slug,
                childrenCategories: [],
              })),
            ],
          ],
        };

        emit('pushMenu', event, menuItem, props.level);
      } catch (e) {
        // TODO: alert
        console.error(e);
      } finally {
        loader.hide();
      }
    }

    const loadTagProducts = async (): Promise<void> => {
      const entities = props.menuItem.columns?.flatMap<
        Pick<UIPageHeaderSection, 'entityId' | 'entityType'>
      >(
        (column) => {
          const sectionsWithProducts = (
            column.filter(
              (section) => 'entityId' in section && 'entityType' in section && section.entityId && section.entityType,
            )
          ).map((section) => ({
            entityId: section.entityId,
            entityType: section.entityType,
          }));
          return [...sectionsWithProducts]
        },
      )

      if (!entities || entities.length === 0) return;

      for (const entity of entities) {
        switch (entity.entityType) {
        case EntityType.TAG:
          try {
            const res = await frontApi.product.getProductBestProductByTag({
              tagId: entity.entityId as number,
            });

            emit('updateProductCache', {
              product: res || null,
              ...entity,
            });
          } catch (e) {
            console.error(e);
          }
          break;
        }
      }
    }

    const checkProductInSection = (section: UIPageHeaderSection): boolean => {
      return !!(
        'entityId' in section &&
        'entityType' in section &&
        section.entityId &&
        section.entityType
      );
    }

    watch(() => props.megaMenuState[props.menuItem.id]?.show, async (newValue) => {
      newValue && await loadTagProducts();
    })

    watch(
      () => props.megaMenuState[props.menuItem.id]?.show,
      () => {
        if (
          !isMobile.value || (
            !props.megaMenuState[props.menuItem.id]?.show ||
            !props.menuItem.columns ||
            props.menuItem.columns.length === 0
          )
        ) {
          return;
        }

        props.menuItem.columns?.some(
          (column) => column.some((section) => {
            if (!('name' in section) || section.childrenCategories.length === 0) {
              return false;
            }

            const sectionElement = sectionDropdowns.value?.find(
              (element) => section.slug && element.href.match(section.slug),
            );

            if (!toggleState.value[section.id]) {
              toggleState.value[section.id] = true;
              if (sectionElement) {
                setTimeout(() => {
                  sectionElement.click(), 100
                });
              }
              return true;
            }
          }),
        );
      },
    );

    return {
      sectionDropdowns,
      toggleState,
      hasChildren,
      handleToggleDropdown,
      loadMenu,
      checkProductInSection,
    }
  },
});
