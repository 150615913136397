export enum UIAlertModalTypeEnum {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export type UIAlertModalPayload = {
  type: UIAlertModalTypeEnum;
  heading?: string;
  content: string;
  button?: {
    label: string;
    callback: CallableFunction;
  }
}
