
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  PropType,
} from 'vue';
import useUIKit from '@/ui/composable/useUIKit';
import useRecaptcha from '@/ui/composable/useRecaptcha';
import useLoader from '@/ui/composable/useLoader';
import useModal from '@/ui/composable/useModal';
import {
  UIPageFooterPayload,
} from './types';
import { UIAlertModalTypeEnum } from '../ui-alert-modal/types';
import { DELIVERY_METHODS, PAYMENT_METHODS } from './consts';
import useBreakpoint from '@/ui/composable/useBreakpoint';
import copyToClipboard from '@/ui/utils/copyToClipboard';
import {frontApi} from '@/js/api/useFrontendApi';
import {ApiError} from '@/js/api/generated';

const PHONE_NUMBER = '33 499 76 76';

export default defineComponent({
  name: 'UIPageFooter',
  props: {
    payload: {
      type: [String, Object] as PropType<string | UIPageFooterPayload>,
      default: {
        seoLinks: [],
        socialLinks: [],
        links: [],
        footerSlot: undefined,
      } as UIPageFooterPayload,
    },
  },

  setup (props) {
    const component = ref<HTMLElement>();
    const recaptcha = useRecaptcha();
    const { showAlert } = useModal();
    const loader = useLoader();
    const { isMobile } = useBreakpoint();

    const email = ref('');
    const copiedNumber = ref(false);

    let isEmailValid = false;

    const footerPayload = computed<UIPageFooterPayload>(() =>
      typeof props.payload === 'string' ?
        JSON.parse(props.payload) as UIPageFooterPayload :
        props.payload,
    );

    const handleSaveNewsletter = async (): Promise<void> => {
      if (!isEmailValid) {
        await showAlert({
          heading: 'Wystąpił błąd',
          type: UIAlertModalTypeEnum.Error,
          content: 'Email jest w nieprawidłowym formacie!',
        });

        return;
      }

      loader.show();

      const token = await recaptcha.check('newsletterSign');

      if (!token) return;

      try {
        const res = await frontApi.newsletter.postNewsletter({
          requestBody: {
            email: email.value,
            captcha: token,
          },
        });

        await showAlert({
          heading: 'Dziękujemy!',
          type: UIAlertModalTypeEnum.Success,
          content: res.message,
        });

        email.value = '';
      } catch (e) {
        console.error(e)
        const apiError = e as ApiError;
        let msg = 'Wystąpił błąd. Odśwież stronę i spróbuj ponownie.';
        if ('body' in apiError) {
          const body = apiError.body as { message?: string, errors?: { email: string[] } };

          if ('message' in body) {
            msg = body.message as string;
          } else if ('errors' in body && body.errors?.email?.length) {
            msg = body.errors.email[0];
          }
        }

        await showAlert({
          heading: 'Wystąpił błąd',
          type: UIAlertModalTypeEnum.Error,
          content: msg,
        });
      } finally {
        loader.hide();
      }
    }

    const handleScrollUp = (): void => {
      document.querySelector('.ui-page-header-container')?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    const handleEmailInput = (event: Event): void => {
      email.value = (event as CustomEvent<string>).detail;
    }

    const handleEmailValidation = (event: Event): void => {
      isEmailValid = (event as CustomEvent<boolean>).detail;
    }

    const handleCopyOrCall = async (event: Event): Promise<void> => {
      if (isMobile.value) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      await copyToClipboard(PHONE_NUMBER, () => {
        copiedNumber.value = true;
      }, () => {
        copiedNumber.value = false;
      });
    }

    onMounted(async () => {
      await useUIKit(component, 'ui-page-footer');
    });

    return {
      DELIVERY_METHODS,
      PAYMENT_METHODS,
      PHONE_NUMBER,
      component,
      email,
      copiedNumber,
      footerPayload,
      now: (new Date()).getFullYear(),
      handleSaveNewsletter,
      handleScrollUp,
      handleEmailInput,
      handleEmailValidation,
      handleCopyOrCall,
    }
  },
});
