import { UIAlertModalTypeEnum } from '../components/ui-alert-modal/types';
import isCsr from '../utils/isCsr';
import useModal from './useModal';

interface UseRecaptcha {
  check: (action: string) => Promise<string | null>;
}

export default function useRecaptcha (): UseRecaptcha {
  const {
    showAlert,
  } = useModal();

  const siteKey = isCsr ? document.body.dataset.siteKey : undefined;

  const loadScript = async (): Promise<void> => {
    if (siteKey === undefined || siteKey === '') {
      throw new Error('invalid recaptcha token');
    }

    return new Promise((resolve, reject) => {
      if (document.querySelector('script#google_recaptcha')) {
        resolve()
        return;
      }

      const recaptchaScript = document.createElement('script');
      recaptchaScript.id = 'google_recaptcha';
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      document.body.appendChild(recaptchaScript);
      recaptchaScript.onload = (): void => resolve();
      recaptchaScript.onerror = (): void => reject('recaptcha script error');
    })
  };

  const ready = async (): Promise<void> => {
    return new Promise((resolve) => {
      grecaptcha.ready(() => resolve());
    });
  };

  const check = async (action: string): Promise<string | null> => {
    try {
      await loadScript();
      await ready();

      if (!siteKey) {
        throw new Error();
      }

      const token = await grecaptcha.execute(siteKey, { action })

      if (!!token && token.length === 0) {
        throw new Error();
      }

      return token;
    } catch (e) {
      console.error(e);
      await showAlert({
        heading: 'Wystąpił błąd',
        type: UIAlertModalTypeEnum.Error,
        content: 'Google uznało że jesteś robotem. Odśwież stronę i spróbuj ponownie.',
      });
    }

    return null;
  };

  return {
    check,
  }
}
