import { Product } from '@/js/api/generated';
import useMercure from '../useMercure';
import {
  IziBindingPlaceEnum,
  IziGetBrowserDataResult,
  IziGetIsBoundResult,
  IziGetOrderCompleteResult,
  IziGetPayDataResult,
  IziMobileLinkResult,
} from '@/ui/types/inpostPay';
import { NavigatorUADataBrand } from '@/ui/types/navigator';
import { UIMercureEvent } from '../../types/mercure';
import { UICartUpdateEvent, UICartUpdateEventSource } from '../useCart';
import mercureEventHandlerStrategy from './mercureEventHandlerStrategy';
import { StrategyNotExistsException } from './exceptions';
import { BasketConfirmedEventPayload } from './types';
import { frontApi } from '@/js/api/useFrontendApi';
import useModal from '../useModal';
import { UIAlertModalTypeEnum } from '@/ui/components/ui-alert-modal/types';
import { Ref, ref } from 'vue';
import isCsr from '@/ui/utils/isCsr';

interface UseInpostPay {
  handle: (cartSummaryButtonRef: Ref<HTMLElement>) => void;
  emitUpdate: (count: number) => void;
}

export default function useInpostPay (): UseInpostPay {
  const isSandboxMode = isCsr && window.uiInpostPayEnv === 'sandbox';

  const mercure = useMercure(isSandboxMode);
  const { showAlert } = useModal();

  const cartSummaryButton = ref<HTMLElement>();

  let basketMode = false;

  /**
   * Method is requesting for binding browser with InPost Pay depending on
   * binding method choosed by user. A method is firing by InPost Pay widget
   * first time after user trigger a basket binding. Default binding method is set to DEEP_LINK.
   * A method is firing second time after user has input phone number and requests for binding.
   *
   * @param prefix string
   * @param phoneNumber string
   * @param bindingPlace string
   *
   * @returns Promise
   */
  const iziGetPayData = async (
    prefix: string,
    phoneNumber: string,
    bindingPlace: IziBindingPlaceEnum,
  ): Promise<IziGetPayDataResult> => {
    debug('iziGetPayData', prefix, phoneNumber, bindingPlace)

    const browserData = iziGetBrowserData() as IziGetBrowserDataResult;

    try {
      const res = await frontApi.inpostPay.inpostPayBindBasket({
        requestBody: {
          binding_method: phoneNumber ? 'PHONE' : 'DEEP_LINK',
          binding_place: bindingPlace,
          browser_data: browserData,
          phone_number: phoneNumber ? {
            phone: phoneNumber || undefined,
            country_prefix: prefix ? '+' + prefix.toString() : undefined,
          } : undefined,
        },
      });

      if (res.deepLink && res.deepLinkHms && res.qrCode) {
        return {
          deep_link: res.deepLink,
          deep_link_hms: res.deepLinkHms,
          qr_code: res.qrCode,
        };
      }

      return [];
    } catch (e) {
      await showAlert({
        content: 'Wystąpił błąd podczas tworzenia koszyka Inpost Pay. Spróbuj ponownie później.',
        type: UIAlertModalTypeEnum.Error,
        heading: 'Nie udało się',
      });

      throw isSandboxMode ? e : 'Wystąpił niespodziewany błąd';
    }
  }

  /**
   * Method extracts browser navigator info and returns object or base64 string
   * depending on the base64 param set.
   *
   * @param base64 boolean
   * @returns object | string
   */
  const iziGetBrowserData = (base64 = false): IziGetBrowserDataResult | string => {
    debug('iziGetBrowserData', base64);

    const architecture = ((brandObject?: NavigatorUADataBrand): string | undefined => {
      return brandObject ? `${brandObject.brand}/${brandObject.version}` : undefined;
    })(navigator?.userAgentData?.brands?.[0]);

    const browserData = {
      architecture: architecture || navigator.appVersion,
      description: navigator.appCodeName || 'Mozilla',
      platform: navigator?.userAgentData?.platform || navigator?.platform,
      user_agent: navigator.userAgent,
    };

    return base64 ? btoa(JSON.stringify(browserData)) : browserData;
  };

  /**
   * Method subscribes specific mercure topic and waiting for binding signal.
   *
   * @returns Promise
   */
  const iziGetIsBound = async (): Promise<IziGetIsBoundResult> => {
    debug('iziGetIsBound');

    return new Promise((resolve) => mercure.subscribe<BasketConfirmedEventPayload>({
      topic: window.uiMercureInpostTopics.Binding,
      callback: ({ data, event }: UIMercureEvent<BasketConfirmedEventPayload>) => {
        try {
          mercureEventHandlerStrategy({
            event,
            payload: data,
            options: {
              basketMode,
            },
            resolve,
          });
        } catch (e) {
          if (e instanceof StrategyNotExistsException) {
            console.error(`${e.className} strategy not exists`);
          } else {
            console.error(e);
          }
        }
      },
    }));
  };

  /**
   * Method subscribes specific mercure topic and waiting for basket update signal.
   * When BasketOrderEventResult event has beed received, then promise is resolving
   * with event payload body.
   *
   * @returns Promise
   */
  const iziGetOrderComplete = (): Promise<IziGetOrderCompleteResult> => {
    debug('iziGetOrderComplete');

    return new Promise((resolve) => mercure.subscribe<unknown>({
      topic: window.uiMercureInpostTopics.Basket,
      callback: (data) => {
        try {
          mercureEventHandlerStrategy({
            event: data.event,
            payload: data,
            options: {
              basketMode,
            },
            resolve,
          });
        } catch (e) {
          if (e instanceof StrategyNotExistsException) {
            console.error(`${e.className} strategy not exists`);
          } else {
            console.error(e);
          }
        }
      },
    }));
  };

  /**
   * Method is use to unbind existsing Inpost Pay basket.
   * A method returns Promise to make sure that unbinding process goes well.
   * Returned promise is awaiting inside IZI script.
   *
   * @returns Promise
   */
  const iziBindingDelete = async (): Promise<void> => {
    debug('iziBindingDelete');

    return frontApi.inpostPay.inpostPayUnbindBasket({});
  };

  /**
   * Method checks that product can be added to Inpost Pay basket.
   *
   * @param productId number
   * @returns boolean
   */
  const iziCanBeBound = async (productId?: Product['productId']): Promise<boolean> => {
    debug('iziCanBeBound', productId);

    try {
      const res = productId ?
        await frontApi.inpostPay.inpostPayCanBeBoundOnProductPage({
          productId: productId.toString(),
        }) : await frontApi.inpostPay.inpostPayCanBeBoundOnOrderPage()

      if (res?.can_be_bound) {
        return true;
      }

      await showAlert({
        content: 'Posiadasz w koszyku produkty lub ich ilości, które nie mogą zawierać się w koszyku Inpost Pay.',
        type: UIAlertModalTypeEnum.Error,
        heading: 'Nie udało się',
      });
    } catch (e) {
      console.error(e);

      await showAlert({
        content: 'Wystąpił błąd podczas tworzenia koszyka Inpost Pay. Spróbuj ponownie później.',
        type: UIAlertModalTypeEnum.Error,
        heading: 'Nie udało się',
      });
    }

    return false;
  };

  /**
   * Method is fired before binding basket to make sure that binded basket will be not empty.
   *
   * @param productId number
   * @returns boolean;
   */
  const iziAddToCart = (productId: Product['productId']): boolean => {
    debug('iziAddToCart', productId);

    document.dispatchEvent(new CustomEvent('cart_add_product', {
      detail: {
        items: [{
          id: productId,
          quantity: 1,
        }],
        source: 1,
        type: 'Inpost Pay',
        copy: 'Utwórz koszyk z Inpost Pay',
        disableModal: true,
      },
    }));

    return true;
  };

  /**
   * Method fetches mobile app deep link for existsing and linked basket.
   * @returns string
   */
  const iziMobileLink = async (): Promise<IziMobileLinkResult> => {
    debug('iziMobileLink');

    try {
      const deepLink = await frontApi.inpostPay.inpostPayMobileLink();

      if (deepLink?.phoneLink === undefined) {
        throw new Error('Cannot fetch Inpost Pay deep link')
      }

      return {
        link: deepLink.phoneLink,
      };
    } catch (e) {
      console.error(e);
      throw isSandboxMode ? e : 'Wystąpił niespodziewany błąd';
    }
  };

  /**
   * Method installs izi methods on window object
   * and bootstraps izi script depends on environment
   */
  const handle = async (
    cartSummaryButtonRef: Ref<HTMLElement>,
  ): Promise<void> => {
    if (!window.uiInpostPayEnabled) {
      return;
    }

    cartSummaryButton.value = cartSummaryButtonRef.value;

    await bindWidgets();

    // TODO: check consent
    window.iziGetPayData = iziGetPayData;
    window.iziGetBrowserData = iziGetBrowserData;
    window.iziGetIsBound = iziGetIsBound;
    window.iziGetOrderComplete = iziGetOrderComplete;
    window.iziBindingDelete = iziBindingDelete;
    window.iziCanBeBound = iziCanBeBound;
    window.iziAddToCart = iziAddToCart;
    window.iziMobileLink = iziMobileLink;

    const script = document.createElement('script');
    script.src = isSandboxMode ?
      'https://izi-sandbox.inpost.pl/inpostizi.js' :
      'https://izi.inpost.pl/inpostizi.js';
    script.onload = (): void => {
      window.handleInpostIziButtons();

      basketMode && document.querySelector(
        '.actions-container',
      )?.classList.add('actions-container--with-inpost-pay');
    }
    document.body.appendChild(script);

    document.addEventListener('cart_update', async (e) => {
      const detail = (e as CustomEvent<UICartUpdateEvent>).detail
      emitUpdate(detail.cartCount);

      if (!cartSummaryButton.value) {
        return;
      }

      cartSummaryButton.value?.classList.add('--bounced')
      cartSummaryButton.value.ontransitionend = (): void => {
        if (cartSummaryButton.value) {
          cartSummaryButton.value.ontransitionend = (): void => {};
        }

        cartSummaryButton.value?.classList.remove('--bounced')
      }

      if (detail.source === UICartUpdateEventSource.Cart) {
        await bindWidgets(true);
      }
    })
  }

  /**
   * Method is binding current inpost widget with bind data. Its used too for rebind
   * widget after the new widget will render in DOM.
   *
   * @param rebind boolean
   */
  const bindWidgets = async (rebind = false): Promise<void> => {
    const compose: Array<(node: HTMLElement) => void> = [
      (node): void => {
        if (node.getAttribute('binding_place') === IziBindingPlaceEnum.BasketSummary) {
          basketMode = true;
        }
      },
    ];

    /**
     * Check is user potentialy binded with inpost pay and fetch client detail if is.
     */
    if (document.cookie.match(/BrowserId/)) {
      try {
        const bindingData = await frontApi.inpostPay.inpostPayGetBindBasket({});

        bindingData.basketLinked && bindingData.clientDetails?.maskedPhoneNumber &&
        compose.push((node: HTMLElement): void => {
          node?.setAttribute(
            'masked_phone_number',
            bindingData.clientDetails!.maskedPhoneNumber as string,
          )
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (compose.length) {
      influenceWidget(
        (node) => compose.forEach((fn) => node && fn(node as HTMLElement)),
      );
    }

    rebind && window.handleInpostIziButtons();
  }

  /**
   * Method is dispatching inpost-update-count event on inpost widget buttons.
   *
   * @param count number
   */
  const emitUpdate = (count: number): void => {
    const event = new CustomEvent<number>('inpost-update-count', {
      detail: count,
    });

    influenceWidget((node) => node.dispatchEvent(event));
  }

  /**
   * Method searches the DOM tree for inpost widget button elements
   * and influence on them with callback method.
   *
   * @param count number
   */
  const influenceWidget = (callback: (node: Element | ShadowRoot) => void): void => {
    const dive = (node: Element | ShadowRoot): void => {
      node = 'shadowRoot' in node && node.shadowRoot ? node.shadowRoot : node;

      Array.from(node.children).forEach((childNode) => {
        childNode.nodeName === 'INPOST-IZI-BUTTON' && callback(childNode);
        childNode.hasChildNodes() && dive(childNode);
      });
    }

    dive(document.body);
  }

  const debug = (...args: unknown[]): void => {
    isSandboxMode && console.debug('[InpostPay debug]', args);
  }

  return {
    handle,
    emitUpdate,
  }
}
