import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.href ? 'a' : 'button'), {
    id: _ctx.id,
    ref: "component",
    href: _ctx.href,
    class: _normalizeClass({
      'ui-button': true,
      '--ghost': _ctx.ghost,
      '--disabled': _ctx.disabled,
      '--expand': _ctx.expand,
      '--glue-left': _ctx.glueLeft,
      '--glue-right': _ctx.glueRight,
      '--icon': _ctx.icon,
      '--rounded': _ctx.rounded,
      '--nogutter': _ctx.nogutter,
      '--noradius': _ctx.noradius,
      '--nowrap': _ctx.nowrap,
      '--strong': _ctx.strong,
      '--text-overflow': _ctx.textOverflow,
      '--tight': _ctx.tight,
      '--inverted': _ctx.inverted,
      [`--${_ctx.size.toString()}`]: true,
      [`--gutter-${_ctx.gutter}`]: _ctx.gutter !== 'md',
      [`ui-b-${_ctx.borderColor?.toString()}-${_ctx.borderDensity}`]: _ctx.borderColor,
      [`ui-b-${_ctx.color.toString()}-${_ctx.density}`]: !_ctx.borderColor,
      [`ui-t-${_ctx.color.toString()}-${_ctx.density}`]: _ctx.inverted,
      [`ui-bg-${_ctx.color.toString()}-${_ctx.density}`]: !_ctx.inverted,
      'ui-t-color-inverted': !_ctx.inverted && !_ctx.disabled && Number(_ctx.density) >= 300,
      'ui-t-color': !_ctx.inverted && !_ctx.disabled && Number(_ctx.density) < 300,
    }),
    target: _ctx.href && _ctx.target ? _ctx.target : undefined,
    title: _ctx.title,
    disabled: _ctx.disabled,
    rel: _ctx.rel,
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prefix"),
      _renderSlot(_ctx.$slots, "default"),
      _renderSlot(_ctx.$slots, "suffix")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id", "href", "class", "target", "title", "disabled", "rel", "onClick"]))
}