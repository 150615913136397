import { Ref, nextTick, ref } from 'vue';
import isCsr from '../utils/isCsr';

interface UseAuth {
  isUserAuthenticated: Ref<boolean>;
}

export default function useAuth (): UseAuth {
  const isUserAuthenticated = ref(false);

  // SSR hydration
  nextTick().then(() => {
    isUserAuthenticated.value = isCsr ? window.auth : false;
  }).catch(() => {});

  return {
    isUserAuthenticated,
  }
}
