import { domainsForSimilar } from '@/js/const/mailSimilarityDomains';
import { stringSimilarity } from '@/js/utils/stringSimilarity';

export function findSimilarDomain (typedDomain: string): null | string {
  const threshold = 0.4;
  let bestMatch = null;
  let highestSimilarity = 0;

  domainsForSimilar.forEach((domain) => {
    const similarity = stringSimilarity(typedDomain, domain);
    if (similarity > highestSimilarity && similarity >= threshold) {
      highestSimilarity = similarity;
      bestMatch = domain;
    }
  });

  return bestMatch;
}
