export const DELIVERY_METHODS = [
  {
    src: '/assets/ui-kit/images/footer/dpd.svg',
    name: 'DPD',
  },
  {
    src: '/assets/ui-kit/images/footer/inpost-kurier.svg',
    name: 'InPost Kurier',
  },
  {
    src: '/assets/ui-kit/images/footer/inpost-paczkomaty.svg',
    name: 'InPost Paczkomaty 24/7',
  },
  {
    src: '/assets/ui-kit/images/footer/orlen-paczka.svg',
    name: 'Orlen Paczka',
  },
  {
    src: '/assets/ui-kit/images/footer/poczta-polska.svg',
    name: 'Poczta Polska',
  },
  {
    src: '/assets/ui-kit/images/footer/pocztex.svg',
    name: 'Pocztex',
  },
];

export const PAYMENT_METHODS = [
  {
    src: '/assets/ui-kit/images/footer/blik.svg',
    name: 'Blik',
  },
  // {
  //   src: '/assets/ui-kit/images/footer/inpost-pay.svg',
  //   name: 'InPost Pay',
  // },
  {
    src: '/assets/ui-kit/images/footer/visa.svg',
    name: 'Visa',
  },
  {
    src: '/assets/ui-kit/images/footer/mastercard.svg',
    name: 'Mastercard',
  },
  {
    src: '/assets/ui-kit/images/footer/payu.svg',
    name: 'PayU',
  },
  {
    src: '/assets/ui-kit/images/footer/payu-raty.svg',
    name: 'PayU Raty 5x0%',
  },
  // {
  //   src: '/assets/ui-kit/images/footer/google-pay.svg',
  //   name: 'Google Pay',
  // },
  {
    src: '/assets/ui-kit/images/footer/apple-pay.svg',
    name: 'Apple Pay',
  },
  {
    src: '/assets/ui-kit/images/footer/paypo.svg',
    name: 'PayPo',
  },
];
