export default function createRipple (event: MouseEvent, target: Node): void {
  const button = target as HTMLElement;
  const bounding = button.getBoundingClientRect();

  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - bounding.left - radius}px`;
  circle.style.top = `${event.clientY - bounding.top - radius}px`;
  circle.classList.add('-ripple');

  const ripple = button.getElementsByClassName('-ripple')[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}
