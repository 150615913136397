import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  UIMercureEvent,
  UIMercureExceptionCodeEnum,
  UIMercureExcpetion,
  UIMercureSubscribePayload,

} from '../types/mercure';
import isCsr from '../utils/isCsr';

interface UseMercure {
  subscribe: <Data>(payload: UIMercureSubscribePayload<Data>) => void;
  close: (topic: string) => void;
}

export default function useMercure (
  isDebugMode = false,
): UseMercure {
  if (isCsr && !window.uiMercureSources) {
    window.uiMercureSources = {};
  }

  /**
   * Method retrives JWT token from window object.
   *
   * @throws UIMercureExcpetion
   * @returns string
   */
  const retrieveToken = (): string => {
    if (!window.uiMercureJwt?.length) {
      throw new UIMercureExcpetion({
        code: UIMercureExceptionCodeEnum.EmptyTokenError,
        message: 'Token is empty or not exists',
      })
    }

    return window.uiMercureJwt;
  }

  /**
   * Method retrieve existing event source from sources collection
   * or create new one in other case.
   *
   * @param topic string
   *
   * @throws UIMercureExcpetion
   * @returns EventSourcePolyfill
   */
  const retrieveEventSource = (topic: string): EventSourcePolyfill => {
    if (window.uiMercureSources[topic]) {
      return window.uiMercureSources[topic];
    }

    try {
      window.uiMercureSources[topic] = new EventSourcePolyfill(
        `${window.uiMercureHost}?topic=${topic}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${retrieveToken()}`,
          },
        },
      );
    } catch (e) {
      throw new UIMercureExcpetion({
        code: UIMercureExceptionCodeEnum.CreateEventSourceError,
        message: e as string,
      });
    }

    return window.uiMercureSources[topic];
  }

  /**
   * Method is used to subscribe Mercure topic.
   * When message has been received then run callback
   * with response data passed in those callback argument.
   * Depending on argument closeImmediately the topic is closing
   * after first message has been received or keep alive in other case.
   *
   * @param payload UIMercureSubscribePayload
   *
   * @throws UIMercureExcpetion
   */
  const subscribe = <Data>(payload: UIMercureSubscribePayload<Data>): void => {
    isDebugMode && console.debug('[EventSource]', `Listening on ${payload.topic}`);
    try {
      retrieveEventSource(payload.topic).onmessage = (event): void => {
        isDebugMode && console.debug('[EventSource]', payload.topic, event);
        payload.callback(JSON.parse(event.data as string) as UIMercureEvent<Data>);
        payload.closeImmediately && close(payload.topic);
      }
    } catch (e) {
      throw new UIMercureExcpetion({
        code: UIMercureExceptionCodeEnum.GeneralError,
        message: e as string,
      });
    }
  }

  /**
   * Method used to closing existent event source.
   *
   * @param topic string
   * @throws UIMercureExcpetion
   */
  const close = (topic: string): void => {
    if (!window.uiMercureSources[topic]) {
      throw new UIMercureExcpetion({
        code: UIMercureExceptionCodeEnum.NonExistentEventSourceError,
        message: 'Could not close non-existent event source',
      });
    }

    window.uiMercureSources[topic].close();
  }

  return {
    subscribe,
    close,
  }
}
