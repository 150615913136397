
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  PropType,
  nextTick,
} from 'vue';
import UIPageHeaderView from './UIPageHeaderView.vue';
import UITextfield from '../ui-textfield/UITextfield.vue';
import UIButton from '../ui-button/UIButton.vue';
import useUIKit from '@/ui/composable/useUIKit'; 7
import useCart from '@/ui/composable/useCart';
import usePrice from '@/ui/composable/usePrice';
import useLockBody from '@/ui/composable/useLockBody';
import useInpostPay from '@/ui/composable/useInpostPay';
import useLoader from '@/ui/composable/useLoader';
import useUserNotification from '@/ui/composable/useUserNotification';
import useBreakpoint from '@/ui/composable/useBreakpoint';
import useWebcomponent from '@/ui/composable/useWebcomponent';
import useModal from '@/ui/composable/useModal';
import useAuth from '@/ui/composable/useAuth';
import useEnsureGtmCallback from '@/ui/composable/useEnsureGtmCallback';
import useLuigiBox from '@/ui/composable/useLuigiBox';
import uuid from '@/ui/utils/uuid';
import isCsr from '@/ui/utils/isCsr';
import isTouchDevice from '@/ui/utils/isTouchDevice';
import Route from '@/js/utils/route';
import { frontApi } from '@/js/api/useFrontendApi';
import {
  UIPageHeaderSeasonBanner,
  UIPageHeaderMegaMenuState,
  UIMegaMenu,
  UIPageHeaderMenuItem,
} from './types';
import { EntityType, MegaMenu, Product } from '@/js/api/generated';
import {
  UISearchDropdownSuggestionItem,
  UISearchDropdownSuggestionsList,
} from '../ui-search-dropdown/types';
import {UIPageFooterPayload} from '@/ui/components/ui-page-footer/types';

const DEFAULT_MENU = 1450;
const MEGAMENU_RECTANGLE_HEIGHT = 90;
const NAV_FIXED_SCROLL_UP_BP = 60;
const NAV_FIXED_SCROLL_DOWN_BP = 112;

const mapMegaMenuToUIMegaMenu = (menu: MegaMenu): UIMegaMenu => {
  const uiMegaMenu: UIMegaMenu = {
    promo: menu.promo?.map((item) => ({
      name: item.name ?? '',
      slug: item.slug ?? undefined,
    })) ?? [],
    links: menu.links?.map((item) => ({
      name: item.name ?? '',
      slug: item.slug ?? undefined,
      icon: item.icon ?? undefined,
    })) || [],
    main: menu.main.map((item) => ({
      name: item.name ?? '',
      slug: item.slug ?? undefined,
      entityId: item.entityId ?? undefined,
      toggleMegaMenu: item.toggleMegaMenu ?? false,
      visibility: item.visibility,
      id: uuid(),
      imageSrc: item.imageSrc ?? undefined,
      fontWeight: item.fontWeight ?? undefined,
      fontColor: item.fontColor ?? undefined,
      fontColorDensity: item.fontColorDensity ?? undefined,
      columns: item.columns?.map((item) => {
        return item.sections.map((section) => ({
          id: uuid(),
          entityId: section.entityId ?? undefined,
          entityType: section.entityType ?? undefined,
          name: section.name ?? '',
          slug: section.slug ?? undefined,
          visibility: section.visibility,
          imageSrc: section.imageSrc ?? undefined,
          fontWeight: section.fontWeight ?? undefined,
          fontColor: section.fontColor ?? undefined,
          fontColorDensity: section.fontColorDensity ?? undefined,
          childrenCategories: section.elements ? section.elements.map((element) => ({
            id: uuid(),
            entityId: element.entityId ?? undefined,
            name: element.name || '',
            slug: element.slug ?? undefined,
            visibility: element.visibility,
            imageSrc: element.imageSrc ?? undefined,
            fontWeight: element.fontWeight ?? undefined,
            fontColor: element.fontColor ?? undefined,
            fontColorDensity: element.fontColorDensity ?? undefined,
            hasChildren: element.hasChildren ?? false,
          })) : [],
        })) || [];
      }) ?? undefined,
    })),
    sections: menu.sections ?? [],
    defaultMenu: menu.defaultMenu || DEFAULT_MENU,
  }

  return uiMegaMenu;
}

export default defineComponent({
  name: 'UIPageHeader',

  components: {
    UIPageHeaderView,
    UITextfield,
    UIButton,
  },

  props: {
    seasonalBanner: {
      type: [String, Object] as PropType<string | UIPageHeaderSeasonBanner>,
      default: null,
    },
    isExpandable: {
      type: Boolean,
      default: null,
    },
    menu: {
      type: [String, Object] as PropType<string | MegaMenu>,
      default: null,
    },
    searchPlaceholderText: {
      type: String,
      default: 'Czego dziś szukasz?',
    },
  },

  setup (props) {
    const { pushLock, popLock, checkIsLocked } = useLockBody();
    const {
      count: cartCount,
      total: cartTotal,
      handleCart,
    } = useCart();
    const {
      currency,
      format: formatPrice,
    } = usePrice();
    const {
      isMobile,
    } = useBreakpoint();
    const {
      toggleAsyncModal,
      handleMountModal,
    } = useModal();
    const {
      isUserAuthenticated,
    } = useAuth();
    const {
      ensureGtmCallback,
    } = useEnsureGtmCallback();
    const {
      mount: mountLuigiBox,
    } = useLuigiBox();
    const { handle: handleInPostPay } = useInpostPay(false);
    const { hide: hideLoader } = useLoader();
    const { handle: handleUserNotification } = useUserNotification();

    const component = ref<HTMLElement>();
    const megaMenuElement = ref<HTMLElement>();
    const overlayElement = ref<HTMLElement>();
    const searchElement = ref<HTMLElement>();
    const lbSearchElement = ref<HTMLElement>();
    const cartSummaryButton = ref<HTMLElement>();

    const isFixed = ref(false);
    const isMegaMenuVisible = ref(false);
    const componentReady = ref(false);
    const megaMenuStack = ref<UIPageHeaderMenuItem[]>([]);
    const megaMenuState = ref<UIPageHeaderMegaMenuState>({});
    const searchDropdownVisible = ref(false);
    const searchPhrase = ref('');
    const isSearchDropdownLoading = ref(false);
    const interactive = ref(true);
    const productCache = ref<Record<string, Product | null>>({});

    const searchSuggestions: UISearchDropdownSuggestionsList = [];
    let isTouching = false;
    /**
     * flag that indicates that menu is rendered
     * @type {boolean}
     */
    let isMegaMenuReady = false;
    let triangleDrawed = false;
    let bodyLockId: string;
    let overlayDebounce: ReturnType<typeof setTimeout>;
    let overlayTransition: ReturnType<typeof setTimeout>;

    const isSearchDropdownVisible = computed(
      () => searchDropdownVisible.value && false,
    );

    const seasonalBannerPayload = computed<UIPageHeaderSeasonBanner | null>(
      () => typeof props.seasonalBanner === 'string' ?
        JSON.parse(props.seasonalBanner) as UIPageHeaderSeasonBanner :
        props.seasonalBanner,
    );

    const menuPayload = computed<UIMegaMenu | null>(
      () => props.menu === null ?
        isCsr && window.uiMenu ? mapMegaMenuToUIMegaMenu(window.uiMenu) : null :
        typeof props.menu === 'string' ?
          mapMegaMenuToUIMegaMenu(JSON.parse(props.menu) as MegaMenu) :
          mapMegaMenuToUIMegaMenu(props.menu),
    );

    const handleScroll = (): void => {
      if (isCsr && isFixed.value && window.scrollY < NAV_FIXED_SCROLL_UP_BP) {
        isFixed.value = false;
      } else if (isCsr && !isFixed.value && window.scrollY >= NAV_FIXED_SCROLL_DOWN_BP) {
        isFixed.value = true;
      }
    }

    const toggleMegaMenu = (
      event?: MouseEvent,
      item?: UIPageHeaderMenuItem,
      force = false,
    ): void => {
      /**
       * TODO: some's wrong here
       * Prevent toggle mega menu on touch devices when toggle action is not indicated
       * by click event.
       */
      if (isTouching && !force) {
        event?.stopPropagation();
        event?.preventDefault();
      } else {
        if (isMegaMenuVisible.value) {
          closeMegaMenu(true);
        } else {
          openMegaMenu(item, true);
        }
      }
    }

    /**
     * Method that recalculates the height of the mega menu content block.
     * @return {void}
     */
    const recalcMegamenuHeight = (): void => {
      if (!megaMenuElement.value) return;

      let megaMenuHeight = 0;

      /**
       * Get bounds of the mega menu content block, to make sure that the
       * caluculated height comes from inside the overflowed container.
       */
      const bounds = megaMenuElement.value.querySelector(
        '.-nav-mega__content',
      )?.getBoundingClientRect();

      /**
       * If bound height is greater that inner height of window, set the menu
       * content height to window height. Just menu will be scrollable.
       * Otherwise, set the menu content height to the calculated height.
       */
      if (isCsr && bounds && bounds.y + bounds.height > window.innerHeight) {
        megaMenuHeight = window.innerHeight - bounds.y + MEGAMENU_RECTANGLE_HEIGHT;
      } else {
        megaMenuHeight = bounds ?
          bounds.height + MEGAMENU_RECTANGLE_HEIGHT :
          0;
      }

      /**
       * Run height animation transition.
       */
      megaMenuElement.value.style.setProperty(
        'height',
        `${megaMenuHeight}px`,
      );
    }

    /**
     * Method is used to render and for animating the mega menu.
     *
     * @param {UIPageHeaderMenuItem} item
     * @param {boolean} force - flag used to prevent opening the mega menu when it is already opened in fixed mode
     * @param {boolean} preventPush - whether to prevent pushing the menu view layer
     * @return {void}
     */
    const openMegaMenu = (
      item?: UIPageHeaderMenuItem,
      force = false,
      preventPush = false,
    ): void => {
      /**
       * Do not open menu when it is in fixed mode and not forced.
       * Its preventing from hover events when fixed mode is active.
       */
      if (isFixed.value && !force) return;

      /**
       * Lock the body, set the visible flag and calculate the height of the mega menu if it is not ready yet
       */
      bodyLockId = pushLock();
      isMegaMenuVisible.value = true;
      recalcMegamenuHeight();

      /**
       * Set the mega menu ready flag after animation transition end
       */
      megaMenuElement.value?.addEventListener('transitionend', () => {
        isMegaMenuReady = isMegaMenuVisible.value;
        (megaMenuElement.value as HTMLElement).ontransitionend = null;
      }, {
        once: true,
      });

      /**
       * Reset overlay debounces those works when mega menu state is under changing
       */
      clearTimeout(overlayTransition);
      clearTimeout(overlayDebounce);

      /**
       * Run overlay fade in animation. Zero-delay is used to make opacity transationable
       * cause of initial display is set to 'none'.
       */
      overlayElement.value?.style.setProperty('display', 'block');
      setTimeout(() => {
        overlayElement.value?.style.setProperty('opacity', '1');
      }, 0);

      /**
       * Below instructions is used to render desktop initial view of the mega menu.
       * When specified menu item is defined, it will be pushed to menu state.
       * Otherwise, the default menu will be pushed.
       */
      !preventPush && !isMobile.value && force && pushMenuView(
        undefined,
        item ?? menuPayload.value?.main.find(
          (menuItem) => menuItem.entityId &&
            menuItem.entityId === menuPayload.value?.defaultMenu,
        ),
        0,
        false,
      );

      /**
       * Propagate that mega menu has beed opened
       */
      document.dispatchEvent(new CustomEvent('page_header_menu_open'));
    }

    const closeMegaMenu = (
      force = false,
      preventTouch = false,
    ): void => {
      /**
       * Prevent push action on hover events over mobile breakpoint.
       */
      if (isMobile.value && !force) return;

      /**
       * Do not close menu when it is in fixed mode and not forced.
       * Its preventing from hover events when fixed mode is active.
       */
      if (isFixed.value && !force) return;

      /**
       * When overlay element is undefined, it means that mega menu is not rendered.
       */
      if (!overlayElement.value) return;

      /**
       * Prevent closing mega menu on touch devices when touching is prevented.
       */
      if (isTouching && preventTouch) return;

      /**
       * Unlock the body
       */
      popLock(bodyLockId);

      /**
       * Run hide menu animation
       */
      megaMenuElement.value?.style.setProperty('height', '0px');

      /**
       * We need to set some debounce to make sure that the mega menu element transiton is finished.
       * We cannot use transitionend event on mega menu element because we need to reset
       * debounce when menu will reopen before animation is finished.
       */
      overlayDebounce = setTimeout(() => {
        /**
         * Unset visible flag and mark menu as unready.
         */
        isMegaMenuVisible.value = false;
        isMegaMenuReady = false;

        /**
         * Run overlay fade out animation
         */
        overlayElement.value?.style.setProperty('opacity', '0');
        overlayElement.value?.addEventListener('transitionend', () => {
          overlayElement.value?.style.setProperty('display', 'none');
        }, {
          once: true,
        });

        /**
         * Propagate that mega menu has beed closed
         */
        document.dispatchEvent(new CustomEvent('page_header_menu_close'));
      }, 100);
    }

    const handleKeyup = async (event: KeyboardEvent): Promise<void> => {
      const findRoots = (element: Node): Array<ShadowRoot> => (
        [
          element,
          ...Array.from(
            'querySelectorAll' in element ?
              (element as HTMLElement).querySelectorAll('*') :
              [],
          ),
        ].filter(
          (element) => 'shadowRoot' in element,
        ) as Array<Element & { shadowRoot: ShadowRoot }>
      ).flatMap<ShadowRoot>(
        (element) => [
          element.shadowRoot,
          ...findRoots(element.shadowRoot),
        ],
      );

      switch (event.key) {
      case 'Escape':
        closeMegaMenu(true)
        break;
      case '/':
        if ([document, ...findRoots(document)].some(
          (element) => element.activeElement?.tagName.match(/INPUT|TEXTAREA/))
        ) return;

        await loadSearchDropdown();
        searchElement.value?.dispatchEvent(new CustomEvent('textfield_force_focus'));
        break;
      }
    }

    const closeOnOverlayClick = (event: MouseEvent): void => {
      if (
        !isMegaMenuReady ||
        isMobile.value
      ) return;

      let target: HTMLElement | null = event.target as HTMLElement;

      do {
        if (
          target.classList.contains('-closer') ||
          target.classList.contains('-hamburger') ||
          target.classList.contains('-back')
        ) return;

        target = target.parentElement;
      } while (target);

      closeMegaMenu(true);
    }

    const canMenuViewHandled = (
      touch = true,
    ): boolean => {
      /**
       * Prevent push action on hover events over mobile breakpoint
       */
      if (isMobile.value && !touch) return false;

      /**
       * Prevent push action on non touchable and non mobile devices, when touch is forced
       */
      if (touch && !isTouching && !isMobile.value) return false;

      return true;
    }

    /**
     * Method is used to splice menu stack to level specified by parameter.
     *
     * @param {number} level
     */
    const spliceMenuStackToLevel = (level?: number): void => {
      level !== undefined && level >= 0 && megaMenuStack.value.splice(
        level,
        megaMenuStack.value.length,
      );
    }

    /**
     * Method is used to render next screen of mobile menu.
     *
     * @param {MouseEvent} event
     * @param {UIPageHeaderMenuItem} item
     * @param {number} level
     * @param {boolean} touch Flag used to determine if push action can be invoked on device with hover
     */
    const pushMenuView = (
      event?: MouseEvent,
      item?: UIPageHeaderMenuItem,
      level?: number,
      touch = true,
    ): void => {
      if (!canMenuViewHandled(touch)) return;

      /**
       * Not need to push the menu without children elements
       */
      if (!item?.columns?.length) return;

      /**
       * Push action could be dispatched on anchor, so it should be prevented
       */
      event?.preventDefault();

      spliceMenuStackToLevel(level);

      megaMenuStack.value.push(item);

      /**
       * On mobile touch device run debounced transition for menu view.
       * It must be debounced because menu layer has initial display set to 'none'.
       * On non-touch device not need animated menu layers,
       * so just clear all menu layers view states.
       */
      if (isTouching && isMobile.value && isCsr) {
        overlayTransition = setTimeout(() => {
          megaMenuState.value[item.id].transitional = true;
        }, 1);
      } else {
        Object.keys(megaMenuState.value).forEach((key) => {
          if (key in megaMenuState.value === false) return;

          megaMenuState.value[key].transitional = false;
          megaMenuState.value[key].show = false;
        });
      }

      megaMenuState.value[item.id] = {
        show: true,
        transitional: !isTouching,
      };
    }

    /**
     * Method is used to splice menu layers stack to expected level,
     * and adapt state to spliced menu stack.
     *
     * @param {MouseEvent} event
     * @param {number} level
     * @param {boolean} touch
     */
    const spliceMenuView = (
      event?: MouseEvent,
      level?: number,
      touch = true,
    ): void => {
      if (!canMenuViewHandled(touch)) return;

      /**
       * Push action could be dispatched on anchor, so it should be prevented
       */
      event?.preventDefault();

      spliceMenuStackToLevel(level);

      /**
       * Collection of states that should be reset to get expected level
       */
      const stateToReset: Array<keyof UIPageHeaderMegaMenuState> = [];

      /**
       * After menu stack has been spliced, we need to reset menu display state.
       * Collect every item on menu stack that not contain in menu state.
       */
      Object.keys(megaMenuState.value).forEach((stateId) => {
        !megaMenuStack.value.find(
          (stackItem) => stackItem.id === stateId,
        ) && stateToReset.push(stateId);
      });

      if (stateToReset.length === 0) {
        return;
      }

      /**
       * Disable transitions for all menu layers.
       */
      stateToReset.forEach((stateId) => {
        megaMenuState.value[stateId].transitional = false;
      });

      /**
       * On touch device, run debounced hide of layers that are subject of reset.
       * Otherwise just hide all those menu layers immediatly.
       */
      if (isTouching) {
        overlayTransition = setTimeout(() => {
          stateToReset.forEach((stateId) => {
            megaMenuState.value[stateId].show = false;
          });
        }, 150);
      } else {
        stateToReset.forEach((stateId) => {
          megaMenuState.value[stateId].show = false;
        });
      }
    }

    /**
     * Method is used to init hover-helper triangle when mouseenter event has occured.
     * Working only on non touchable devices.
     *
     * @param {MouseEvent} event
     * @param {UIPageHeaderMenuItem} item
     */
    const drawTriangle = (
      event: MouseEvent,
      item: UIPageHeaderMenuItem,
    ): void => {
      if (isTouching) return;

      triangleDrawed = true;
      redrawTriangle(event, item);
    }

    /**
     * Method is used to draw hover-helper triangle for hover on menu item.
     * It gives user more hover area when they want to
     * move cursor from menu item to menu content container.
     *
     * @param {MouseEvent} event
     * @param {UIPageHeaderMenuItem} item
     */
    const redrawTriangle = (
      event: MouseEvent,
      item: UIPageHeaderMenuItem,
    ): void => {
      /**
       * Do not draw triangle if any menu item is not hovered.
       */
      if (!triangleDrawed) return;

      /**
       * Find the target menu item link where hover-helper triangle will be drawed.
       */
      const target = (
        event.target as HTMLElement
      ).classList.contains('-nav-mega__menu-link') ?
        event.target as HTMLElement :
        (event.target as HTMLElement).closest(
          '.-nav-mega__menu-link',
        ) as HTMLElement;

      /**
       * Calculate the hover-helper triangle position.
       */
      const targetBounds = target.getBoundingClientRect();
      const hover: {
        x: number;
        width: number;
      } = {
        x: event.clientX - targetBounds.x,
        width: targetBounds.width - (event.clientX - targetBounds.x),
      }

      /**
       * Triangle will be re-drawed only when user move cursor far from menu content container.
       * Otherwise when user moves the cursor closer to the container,
       * the triangle stay in the same place.
       */
      if (!megaMenuState.value[item.id]?.hover) {
        megaMenuState.value[item.id] = {
          ...megaMenuState.value[item.id],
          hover,
        }
      } else if ((megaMenuState.value[item.id]?.hover?.x || -1) > hover.x) {
        megaMenuState.value[item.id].hover = hover;
      }
    }

    /**
     * Method is used to erase hover-helper triangle when mouseleave event has occured.
     *
     * @param {UIPageHeaderMenuItem} item
     */
    const eraseTriangle = (item: UIPageHeaderMenuItem): void => {
      triangleDrawed = false;

      if (megaMenuState.value[item.id]) {
        megaMenuState.value[item.id].hover = undefined;
      }
    }

    const handlePushMenuView = (
      event: MouseEvent,
      item?: UIPageHeaderMenuItem,
      level?: number,
      touch = true,
    ): void => pushMenuView(event, item, level, touch);

    const handleSpliceMenuView = (
      event: MouseEvent,
      level?: number,
      touch = true,
    ): void => spliceMenuView(event, level, touch);

    const handleToggleMegaMenu = (
      event: MouseEvent,
      item?: UIPageHeaderMenuItem,
      force = false,
    ): void => toggleMegaMenu(event, item, force);

    const handleResize = (): void => {
      setAppHeight();

      if (!megaMenuElement.value || !isMegaMenuVisible.value) return;

      if (checkIsLocked(bodyLockId)) {
        // TODO: not working. All of menu items has hover state.
        setTimeout(() => openMegaMenu(undefined, true), 500);
      }
    }

    const loadSearchDropdown = async (): Promise<void> => {
      await useWebcomponent('ui-search-modern-dropdown');
      bodyLockId = pushLock();
      searchDropdownVisible.value = true;
    }

    const handleSearchDropdownClose = (): void => {
      popLock(bodyLockId);
      searchDropdownVisible.value = false;
      isSearchDropdownLoading.value = false;
      if (searchElement.value && typeof searchElement.value?.dispatchEvent !== 'undefined') {
        searchElement.value?.dispatchEvent(new CustomEvent('textfield_force_blur'));
      }
    }

    const handleSearchPhraseUpdate = async (
      phrase: string,
    ): Promise<void> => {
      searchPhrase.value = phrase;

      await nextTick(() => lbSearchElement.value?.dispatchEvent(new KeyboardEvent('input')));
    }

    const handleSearchDropdownLoading = (
      event: Event,
    ): void => {
      isSearchDropdownLoading.value = (
        event as CustomEvent<boolean>
      ).detail;
    }

    const handleSearchSubmit = async (): Promise<void> => {
      if (searchPhrase.value.length === 0) return;

      popLock(bodyLockId);
      searchDropdownVisible.value = false;

      const phrase = searchPhrase.value;

      try {
        await submitSearch(
          undefined,
          searchSuggestions,
          () => {
            location.href = `${Route.get('search')}?query=${phrase}`;
          },
        );
      } catch (e) {
        if (isCsr) {
          window.location.href = `${Route.get('search.results', {
            slug: '',
          })}?query=${phrase}`;
        }
      }
    }

    const handleSelectedItem = async (
      event: Event,
    ): Promise<void> => {
      const item = (event as CustomEvent<UISearchDropdownSuggestionItem>).detail;

      await submitSearch(
        item,
        searchSuggestions,
        () => {
          if (item?.path && item.path.length > 0) {
            location.href = item.path;
          } else if ('slug' in item && (item.slug as string).length > 0) {
            location.href = item.slug as string;
          }
        },
      )
    }

    const handleUpdateSuggestions = (
      event: Event,
    ): void => {
      const customEvent = event as CustomEvent<UISearchDropdownSuggestionsList>;
      searchSuggestions.splice(0, searchSuggestions.length);
      searchSuggestions.push(...customEvent.detail);
    }

    const submitSearch = async (
      item: UISearchDropdownSuggestionItem | undefined,
      suggestions: UISearchDropdownSuggestionsList,
      callback: (fallbackItem?: UISearchDropdownSuggestionItem) => void,
    ): Promise<void> => {
      const trackSuggestions = async (
        selectedItem?: UISearchDropdownSuggestionItem,
      ): Promise<void> => {
        try {
          await frontApi.search.createDisplayedSuggestionsMonitor({
            requestBody: {
              query: searchPhrase.value,
              clicked: selectedItem?.name || searchPhrase.value,
              suggestionsList: suggestions.map((item) => item.name),
              optionType: selectedItem ?
                (
                  'productId' in selectedItem ?
                    'product' :
                    selectedItem.type ?? 'search'
                ) : 'search',
            },
          });
        } catch (e) {
          console.error(e);
        }
      }

      if (isCsr && window.google_tag_manager && window.dataLayer) {
        window.pushDataLayer({
          event: 'search',
          search_term: searchPhrase.value,
          event_category: 'interaction',
          event_label: searchPhrase.value,
          eventCallback (id?: string) {
            ensureGtmCallback(
              async () => {
                await trackSuggestions(item);
                callback(item)
              },
              id,
            );
          },
          eventTimeout: 3000,
        });
      } else {
        await trackSuggestions(item);
        callback(item);
      }
    }

    const handleSearchElementFocus = async (): Promise<void> => {
      false && !isSearchDropdownVisible.value && await loadSearchDropdown();
    }

    const handleSearchElementEmptyTab = (event: KeyboardEvent): void => {
      if (searchPhrase.value === '' && event.key === 'Tab') {
        popLock(bodyLockId);
        searchDropdownVisible.value = false;
      }
    }

    const setAppHeight = (): void => {
      document.body.parentElement?.style.setProperty(
        '--app-height',
        isCsr && window.visualViewport ?
          `${window.visualViewport?.height}px` :
          '100vh',
      );
    }

    const handleUpdateProductCache = (payload: {
      product: Product | null,
      entityId: number,
      entityType: EntityType,
    }): void => {
      const key = `${payload.entityType}-${payload.entityId}`;
      productCache.value[key] = payload.product;
    }

    onMounted(async () => {
      if (isCsr) {
        const { host } = await useUIKit(component, 'ui-page-header');
        host?.classList.add('--loaded');
        setAppHeight();
        handleCart();
      }

      componentReady.value = true;
      interactive.value = (
        isCsr && typeof window.uiMenuInteractive !== 'undefined'
      ) ? window.uiMenuInteractive : true;

      /**
       * Extract search phrase from URL
       */
      searchPhrase.value = new URLSearchParams(
        window.location.search,
      ).get('query') ?? searchPhrase.value;

      isTouching = isCsr && isTouchDevice();

      handleScroll();
      handleUserNotification();
      if (isCsr && cartSummaryButton.value) {
        handleInPostPay(cartSummaryButton);
      }
      await handleMountModal();

      /**
       * Reset search hash to avoid search input troubles
       */
      if (isCsr && window.location.hash === '#search') {
        history.pushState('', document.title, window.location.pathname + window.location.search);
      }

      mountLuigiBox();
      isTouching = isCsr && isTouchDevice();
      if (isCsr) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('keyup', handleKeyup);
        window.addEventListener('resize', handleResize);
        window.addEventListener('pageshow', (e) => {
          /**
           * Avoiding screen lock by loader after case when user goes back
           * then the browser was restoring page from cache.
           */
          e.persisted === true && hideLoader();
        });
      }
    });

    onUnmounted(() => {
      if (isCsr) {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('keyup', handleKeyup);
        window.removeEventListener('resize', handleResize);
      }
    });

    return {
      Route,
      component,
      megaMenuElement,
      overlayElement,
      searchElement,
      lbSearchElement,
      cartSummaryButton,
      cartCount,
      cartTotal,
      currency,
      formatPrice,
      isMobile,
      toggleAsyncModal,
      isUserAuthenticated,
      isFixed,
      megaMenuStack,
      megaMenuState,
      isSearchDropdownVisible,
      searchPhrase,
      isSearchDropdownLoading,
      componentReady,
      isMegaMenuVisible,
      seasonalBannerPayload,
      menuPayload,
      interactive,
      productCache,
      toggleMegaMenu,
      openMegaMenu,
      closeMegaMenu,
      closeOnOverlayClick,
      pushMenuView,
      drawTriangle,
      redrawTriangle,
      eraseTriangle,
      handlePushMenuView,
      handleSpliceMenuView,
      handleToggleMegaMenu,
      loadSearchDropdown,
      handleSearchDropdownClose,
      handleSearchDropdownLoading,
      handleSearchSubmit,
      handleSearchElementEmptyTab,
      handleSearchElementFocus,
      handleSearchPhraseUpdate,
      handleSelectedItem,
      handleUpdateSuggestions,
      submitSearch,
      handleUpdateProductCache,
    }
  },
});
